import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const routeNamespaceMap = {
  '/aura-scheduler/contact-us': ['contactUs'],
  '/aura-scheduler/guide': ['guide'],
  '/aura-scheduler/privacy-policy': ['privacyPolicy'],
  '/aura-scheduler/terms-of-service': ['termsOfService'],
  '/dashboard': ['dashboard'],
  '/dashboard/schedules': ['dashboard'],
  '/events': ['events'],
  '/events/[eventId]/edit': ['eventUpload'],
  '/events/upload': ['eventUpload'],
  '/content': ['content'],
  '/courses': ['courses'],
  '/courses/[courseId]/edit': ['courseUpload'],
  '/courses/upload': ['courseUpload'],
  '/communities': ['communities'],
  '/communities/[communityId]/edit': ['communitiesUpload'],
  '/communities/upload': ['communitiesUpload'],
  '/login': ['login'],
  '/live': ['live'],
  '/live/upload': ['liveUpload'],
  '/live/[liveId]': ['live'],
  '/live/[liveId]/edit': ['liveUpload'],
  '/signup': ['signup'],
  '/profile': ['profile'],
  '/profile/create': ['profileCreate'],
  '/onboarding': ['onboarding'],
  '/onboarding/values': ['onboardingValues'],
  '/upload': ['upload'],
  '/tracks/[trackId]/edit': ['editTrack'],
  '/series/[seriesId]/edit': ['editSeries'],
  '/reflections': ['reflections'],
  '/restricted': ['restricted'],
  '/review/courses/[courseId]': ['courseUpload'],
  '/review/communities/[communityId]': ['communitiesUpload'],
};

const getNamespacesForRoute = (route) => {
  const ns = routeNamespaceMap[route] || [];
  ns.push('common');
  return ns;
};

const loadLocale = async ({ locale, route }) => {
  const translations = await serverSideTranslations(
    locale,
    getNamespacesForRoute(route)
  );
  return translations;
};

const I18N = {
  getNamespacesForRoute,
  loadLocale,
};

export default I18N;
